/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Layout, Col, Row, Space, Tabs, DatePicker, Button, Modal, Input, List, Skeleton, Avatar, Typography } from "antd";
import {
  useLocation,
  useHistory,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dotEnv from "dotenv";
import { CaretRightOutlined, CaretLeftOutlined, FileImageOutlined, UserAddOutlined } from "@ant-design/icons";
import { Loading } from "../Common/components/Loading";
import { Reminders } from "../Landing/components/Reminders";
import Schedule from "../Landing/components/Schedule";
import Notes from "../Landing/components/Notes";
import styles from "./styles/CalendarHome.module.css";
import { WeekView } from "./components/WeekView";
import "./styles/Calender.css"
import { useMediaQuery } from "react-responsive";
import { SearchOutlined } from "@ant-design/icons";
import { landingPageAction } from "../Landing/state/actions";
import { EmptyData } from "../Common/components/EmptyData";
dotEnv.config();

const { TabPane } = Tabs;
const { Content } = Layout;


function CalendarHome(props) {

  useEffect(() => {
    if (props.networkConnection === false) {
      window.location.reload();
    }
  }, [props]);
 
  return (
    <Layout
      style={{
        height: "100%",
        padding: "0rem 1rem",
        boxSizing: "border-box",
        marginTop: "-10px",
        width: "100%"
      }}
    >
      <Content
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <Row
          gutter={[4, 24]}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Switch>
              <Route
                exact
                path="/calendar/week"
                component={TabCommon}
              />
              <Route
                exact
                path="/calendar/month"
                component={TabCommon}
              />

              <Route
                exact
                path="/calendar/week/:week"
                component={TabCommon}
              />

              <Route
                exact
                path="/calendar/:date"
                component={TabCommon}
              />

              <Route path="*">
                <Redirect to="/error" />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

function TabCommon() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  let pathnme = pathname?.split("/");
  const idx = (pathnme?.[2] === "month") ? "3" : (pathnme?.[2] === "week") ? "2" : "1";
  let current;
  if (pathnme?.[1] === "calendar") {
    let date = pathnme[2]?.split("-");
    if (Array.isArray(date)) {
      current = (
        moment(new Date(date[0], date[1] - 1, date[2]).setHours(9))
      );
    }
  }
  const [viewType, setViewType] = useState((idx=== "3") ? "month" : (idx=== "2") ? "week" : "day");

  const [currentDate, setCurrentDate] = useState(current);
  const [openDatePicker, setOpenDatepicker] = useState(false);
  const [isvisibleopen, setisVisibleOpen] = useState(false);
  const [eventvalue, setEventvalue] = useState("");
  const [event, setEvent] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTab = useMediaQuery({ minWidth:481,maxWidth:916 });
  const spot = useMediaQuery({ minWidth:609,maxWidth:650 });
  const spot1 = useMediaQuery({ minWidth:481,maxWidth:608 });
  const inputRef = useRef(null);
  const debounceTimeout = useRef(null);

  const { reminderandschedulesuccess, reminderandscheduleloading } = useSelector((state) => state.landingPageReducer.reminderReducer);

  function callback(key, date) {
    if (!date) {
      date = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`;
    }
    if (key === "1") {
      history.push( {
        pathname: `/calendar/${date}`,
        state: {
          refresh: true
        }
      });
    } else if (key === "2") {
      history.push("/calendar/week");
    } else if (key === "3") {
      history.push("/calendar/month");
    }
  }

  useEffect(()=>{
    if(state?.refresh){
      setCurrentDate("");
      let pathnme = pathname?.split("/");
      let date = pathnme[2]?.split("-");
      current = (
        moment(new Date(date[0], date[1] - 1, date[2]).setHours(9))
      );
      setCurrentDate(current);
      window.history.replaceState({}, document.title);
    }
  }, [state])

  // <---------------------code for global search----------------->
  React.useEffect(() => {
    if (isvisibleopen) {
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [isvisibleopen]);

  React.useEffect(()=>{
    if (!isvisibleopen) {
      setEventvalue("");
    }
  },[!isvisibleopen])

  const decrementDate = () => {
    let nextDay = moment(currentDate).subtract(1, "days");
    let date = `${nextDay.year()}-${nextDay.month() + 1}-${nextDay.date()}`;
    history.push({
      pathname: `/calendar/${date}`,
      state: {
        refresh: true
      }
    });
  };

  const incrementDate = () => {
    let nextDay = moment(currentDate).add(1, "days");
    let date = `${nextDay.year()}-${nextDay.month() + 1}-${nextDay.date()}`;
    history.push({
      pathname: `/calendar/${date}`,
      state: {
        refresh: true
      }
    });
  };

  const handleTodayDate = () => {
   let today =  moment(new Date())
    let nextDay = moment(today)
    let date = `${nextDay.year()}-${nextDay.month() + 1}-${nextDay.date()}`;
    history.push({
      pathname: `/calendar/${date}`,
      state: {
        refresh: true
      }
    });
  };

  const selectManualDate = (selectedDate) => {
    let date = `${selectedDate.year()}-${
      selectedDate.month() + 1
    }-${selectedDate.date()}`;
    history.push({
      pathname: `/calendar/${date}`,
      state: {
        refresh: true
      }
    });
    setOpenDatepicker(false);
  };

  let dayIndex = moment(currentDate).weekday();

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let dayOfWeek = weekDays[dayIndex];


  React.useEffect(() => {
    window.addEventListener("message", (ev) => {
      if (ev.data.message === "Date from calendar") {
        const year = ev.data.year;
        const month = ev.data.month;
        const day = ev.data.day;
        callback("1", `${year}-${month}-${day}`);
      }
    });
  }, []);

  const handlereminder = () => {
    setisVisibleOpen(true);
    setEvent(true);
  };

  const handleBlur = () => {
    inputRef.current.input.value = "";
  };

  function handleClick(item) {
    setEventvalue("");
    let timestamp = item.startTimestamp;
    let date = moment(timestamp).format("YYYY-MM-DD");
    history.push({
      pathname: `/calendar/${date}`,
      state: {
        refresh: true
      }
    });
    setisVisibleOpen(false);
    setEvent(true);
    inputRef.current.input.value = "";
 }

  const debounceSearch = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      searchReminders(value);
    }, 2000);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEventvalue(value);
    debounceSearch(value);
  };

 const searchReminders = (value) => {
    setEventvalue(value);
    if (!value) {
      setEvent(true);
    } else {
      setEvent(false);
    }
    if (location.pathname === "/") {
      dispatch(landingPageAction.getRemindersandSchedule(value.trim(), true));
    } else {
      dispatch(landingPageAction.getRemindersandSchedule(value.trim(), false));
    }
  };
  
  return (
    <>
      <div>
        <Tabs defaultActiveKey={idx} onChange={callback} onTabClick={(key) => key === "2" ? setViewType('week') : key === '3' ? setViewType('month') : setViewType('day')}>
          <TabPane
            tab={<span className={styles.tabPaneTitleday}>day</span>}
            key="1"
          >
            {
              (idx === "1") && <div className="landing_page">
                <Col xs={22} sm={22} md={14} lg={14} xl={7}>
                  <div
                    className="mozScrollbar reminderContainer"
                    style={{ height: "calc(100vh - 170px)",margin:"0 -2px 0 -17px" }}
                  >
                    <Space
                      direction="vertical"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Reminders />
                    </Space>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={18} lg={18} xl={11}>
                  <div
                    className="mozScrollbar reminderContainer"
                    style={{ height: "calc(100vh - 170px)",marginRight:"-2px" }}
                  >
                    <Space
                      direction="vertical"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Schedule />
                    </Space>
                  </div>
                </Col>
                <Col span={6} xs={24} sm={24} md={14} lg={8} xl={6}>
                  <div
                    className="mozScrollbar reminderContainer"
                    style={{ height: "calc(100vh - 170px)",marginRight:"-20px", overflow:"hidden" }}
                  >
                    <Space
                      direction="vertical"
                      style={{
                        display: "flex",
                        height:"100%",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Notes source="CALANDER" />
                    </Space>
                  </div>
                </Col>
              </div>
            }
            </TabPane>

            <TabPane
              tab={<span className={styles.tabPaneTitleWeek}>week</span>}
              key="2"
            >
              <WeekView viewType={viewType}/>
            </TabPane>
            {/* <TabPane
              tab={<span className={styles.tabPaneTitleWeek}>Month</span>}
              key="3"
            >
              <WeekView viewType={viewType} />
            </TabPane> */}
          </Tabs>
          {/* causing the UI messup so, commenting it out */}
          {/* {(history.location.pathname.includes("/week") || history.location.pathname.includes("/month")) && (
            <style jsx={true}>
              {`
                .ant-tabs > .ant-tabs-nav {
                  position: absolute !important;
                  z-index: 999 !important;
                  left: 26px !important;
                  top: 13px !important;
                }
              `}
            </style>
          )} */}
          {!(history.location.pathname.includes("/week") || history.location.pathname.includes("/month")) && (
            <div className="calendar_page_DateBox">
              <DatePicker
                open={openDatePicker}
                style={{ visibility: "hidden", width: 0 }}
                onOpenChange={(open) => setOpenDatepicker(open)}
                value={currentDate}
                onChange={(e) => selectManualDate(e)}
                onClickOutside={() => setOpenDatepicker(false)}
              />
              {!isMobile && !isTab ? <>
                <CaretLeftOutlined
                style={{ marginRight: "5px" }}
                onClick={() => decrementDate()}
              />
              <span
                className="calendarDate"
                style={{cursor:"pointer",minWidth:"150px"}}
                onClick={() => setOpenDatepicker(true)}
              >
                {` ${dayOfWeek}, ${moment(currentDate)
                  .format("L")
                  .replace("/", "-")
                  .replace("/", "-")}`}
              </span>
              <CaretRightOutlined
                style={{ marginLeft: "0px" }}
                onClick={() => incrementDate()}
              />
              
              
              <span
                onClick={handleTodayDate}
                style={{marginLeft: "40px",
                pointerEvents:(moment(moment(new Date())).format("MM/DD/YYYY")==moment(currentDate).format("MM/DD/YYYY")) ? 'none' : 'auto',
                position:"relative",
                top:"-3px"
                }}
              >
                <Button
                  type="primary"
                  size="middle"
                  style={{borderRadius:"8px",marginTop:"-7%",fontSize:"16px",
                    background:(moment(moment(new Date())).format("MM/DD/YYYY")==moment(currentDate).format("MM/DD/YYYY")) ? 'gray' : '#4865AA',
                    height:"33px",
                  }}
                > 
                  Reset to Today
                </Button>
              </span>
              </> : 
              
              <div style={{display : "flex",width:"100%",margin:isMobile ? "-6% 0 0 3%" : spot ? "-5% 0 0 4%" : "-7% 0 0 10%"}}>
              <CaretLeftOutlined
                style={{ marginTop:isTab? "2px" : isMobile ? "-4px" : "-3px" }}
                onClick={() => decrementDate()}
              />
              <span
                className="calendarDate"
                style={{cursor:"pointer",minWidth:"200px",marginTop:isMobile ? "-7px" : "-3px"}}
                onClick={() => setOpenDatepicker(true)}
              >
                {` ${dayOfWeek}, ${moment(currentDate)
                  .format("L")
                  .replace("/", "-")
                  .replace("/", "-")}`}
              </span>
              <CaretRightOutlined
                style={{ marginLeft: "0px",marginTop:isTab? "2px" : isMobile ? "-4px" : "-3px" }}
                onClick={() => incrementDate()}
              />
              <span
              onClick={handleTodayDate}
              style={{marginLeft: "40px",
              pointerEvents:(moment(moment(new Date())).format("MM/DD/YYYY")==moment(currentDate).format("MM/DD/YYYY")) ? 'none' : 'auto',
              position:"relative",
              top:spot1 ? "-3px" : isTab ? "-7px" : "-4px"
              }}><Button
              type="primary"
              size="middle"
              style={{borderRadius:"8px",marginTop:"-7px",fontSize:"small",
               background:(moment(moment(new Date())).format("MM/DD/YYYY")==moment(currentDate).format("MM/DD/YYYY")) ? 'gray' : '#4865AA',
               height:"30px",
              }}
              > Reset to Today</Button></span>
              </div>}
              <div style={{position: "absolute", right: -380, marginTop: "-40px" }}>
                <SearchOutlined
                    style={{ fontSize: "25px" }}
                    onClick={ handlereminder }
                />
              </div>
            </div>
        )}
      </div>
       
      <Modal
        style={{ width: "800px" }}
        visible={isvisibleopen}
        onCancel={() => {
          setisVisibleOpen(false);
          setEvent(true);
        }}
        footer={false}
      >
        <Input
          ref={inputRef}
          value={eventvalue}
          onBlur={handleBlur}
          // onChange={(e) => {
          //   searchReminders(e.target.value);
          // }}
          onChange={handleChange}
          // value={searchoffer}
          placeholder={"Search"}
          autoFocus={true}
          allowClear
          style={{
            height: "40px",
            border: "1px solid #085191",
            fontSize: "20px",
          }}
        />
        {reminderandscheduleloading ? (
          <Loading />
        ) : reminderandschedulesuccess &&
          reminderandschedulesuccess?.length > 0 &&
          !event ? (
          <List
            bordered
            size="small"
            style={{ overflow: "auto", height: "500px", overflowX: "hidden" }}
            itemLayout="horizontal"
            dataSource={reminderandschedulesuccess}
            renderItem={(item) => (
              <List.Item onClick={() => handleClick(item)}>
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      item?.taggedPersonIds?.[0]?.profileImg?.original ? (
                        <Avatar
                          size={52}
                          className={styles.useravtar}
                          src={item?.taggedPersonIds?.[0]?.profileImg?.original}
                        />
                      ) : item?.taggedPersonIds?.[0]?.firstName ? (
                        <Avatar size={52} style={{ background: "#085191" }}>
                          {(item?.taggedPersonIds?.[0]?.firstName
                            ? item?.taggedPersonIds?.[0]?.firstName[0]?.toUpperCase()
                            : "") +
                            "" +
                            (item?.taggedPersonIds?.[0]?.lastName
                              ? item?.taggedPersonIds?.[0]?.lastName[0]?.toUpperCase()
                              : "")}
                        </Avatar>
                      ) : (
                        <Avatar
                          shape="circle"
                          size={52}
                          style={{
                            background: "#085191",
                            fontSize: "24px",
                            display: "flex",
                            alignItems: "center",
                            height: "52px",
                          }}
                        >
                          {item?.notes ? (
                            <FileImageOutlined
                              style={{ fontSize: "24px", color: "white" }}
                            />
                          ) : (
                            <UserAddOutlined
                              style={{
                                fontSize: "24px",
                                fill: "#085191",
                                color: "white",
                              }}
                            />
                          )}
                        </Avatar>
                      )
                    }
                    title={
                      <p
                        style={{
                          fontSize: "15px",
                          width: "130%",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.title
                              ? item.title
                              : item.notes
                                ? item.notes
                                : item.firstName + " " + item.lastName,
                          }}
                          style={{
                            textOverflow: "ellipsis",
                            overflowY: "auto",
                            maxHeight: "200px",
                          }}
                        />
                      </p>
                    }
                    description={
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography.Text style={{ marginTop: "15px" }} mark>
                            {item.notes ? "Notes" : "Reminder & Schedule"}
                          </Typography.Text>
                        </div>
                        <div>
                          <Typography.Text style={{ marginTop: "15px" }} mark>
                            {moment(item.startTimestamp).format("MM-DD-YYYY")}
                          </Typography.Text>
                        </div>
                      </div>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        ) : (
          <EmptyData />
        )}
      </Modal>
    </>
  );
}

export { CalendarHome };
