import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { landingPageAction } from "../state/actions";
import { convertDateStringToTimeStamp } from "../utils/utilFunctions";
import { ClientList } from "./../../Client/components/ClientList";
import { Loading } from "../../Common/components/Loading";
import ScheduleModal from "./ScheduleModal";
import { options } from "../../Common/commondata/Option";
import ScheduleList from "./ScheduleList";
import ScheduleHeader from "./ScheduleHeader";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import axios from "axios";
const { confirm } = Modal;

const Tasks = ({ newDate, section }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { state, pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    reminderDate: moment(),
    scheduleTimeArray: moment(),
    timeInterval: 2,
  });
  const [showClientList, setShowClientList] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentScheduleData, setCurrentScheduleData] = useState([]);
  const [currentClickedItem, setCurrentClickeditem] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [value, setValue] = useState("");
  const [page, setPage]=useState([1]);
  const [isfilterArchive, setIsFilterArchive] = useState("");
  const { getSchedulesData, getScheduleLoading, renderSchedule } = useSelector(
    (state) => state.landingPageReducer.scheduleReducer
  );

  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTab = useMediaQuery({ minWidth:521,maxWidth:700 });
  const spot1 = useMediaQuery({ minWidth:1200,maxWidth:1400 });

  useEffect(() => {
    setCurrentScheduleData(getSchedulesData);
  }, [getSchedulesData]);

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    let pathnme = pathname?.split("/");
    let date = pathnme?.[2]?.split("-");
    if (Array.isArray(date)) {
      newDate = ( moment(new Date(date[0], date[1] - 1, date[2]).setHours(9)) );
    }
    dispatch(landingPageAction.getScheduleList({timestamp: moment(newDate).valueOf(), unmounted, source}));

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [state, renderSchedule]);

  useEffect(() => {
   form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const selectClient = (client) => {
    setSelectedClient(client);
    setShowClientList(false);
  };

  // when click on any of the list item
  const handleListItemClick = (item) => {
    if (item.type === "BIRTHDAY") {
     openUpdateSchedule(item._id, item);
      setSelectedClient(item);
    } else {
      openUpdateSchedule(item._id, item);
      setSelectedClient(item?.taggedPersonIds?.[0]);
    }
  };
  let handleDelete = (item) => {
    dispatch(
      landingPageAction.deleteReminderById(
        item._id,
        item.type
      )
    );
  };

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Are you sure you want to delete this Schedule?",
      icon: <ExclamationCircleOutlined />,
      // content: "Once you deleted, Record can not be restored !!!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const showDeallocateConfirm = (id) => {
    confirm({
      title: "Are you sure you want to unlink this user?",
      icon: <ExclamationCircleOutlined />,
      // content: "Once you deleted, Record can not be restored !!!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setSelectedClient(null);
      },
      onCancel() {},
    });
  };
  // <-----------function for schedule update------------->
  const [editcase,seteditcase]=useState(false)
  const openUpdateSchedule = (id, data) => {
    let newData = {};
    if (data.type === "BIRTHDAY") {
      newData = {
        ...data,
        title: data.fullName ? data?.fullName : "",
        description: "BIRTHDAY",
        scheduleTimeArray: moment(data.startTimestamp),
        timeInterval: data.duration || 2,
      };
    } else {
      newData = {
        ...data,
        scheduleTimeArray: moment(data.startTimestamp),
        timeInterval: data.duration || 2,
      };
    }
    setDefaultValues(newData);
    setShowModal(true);
    setCurrentClickeditem(data);
    seteditcase(true)

  };

  const handleOk = (values) => {
   let data;
    if (currentClickedItem.type === "CLIENT_EVENT") {
      data = {
        name: values.title,
        description: values.description,
        dashboardType: "SCHEDULE",
        type: currentClickedItem.type,
        startTimestamp: convertDateStringToTimeStamp(values.scheduleTimeArray),
        endTimestamp: convertDateStringToTimeStamp(
          values.scheduleTimeArray + 60000 * 30 * values.timeInterval
        ),
        duration: values.timeInterval,
      };
    } else if (currentClickedItem.type === "BIRTHDAY") {
      data = {
        dashboardType: "SCHEDULE",
        type: currentClickedItem.type,
        startTimestamp: convertDateStringToTimeStamp(values.scheduleTimeArray),
        endTimestamp: convertDateStringToTimeStamp(
          values.scheduleTimeArray + 60000 * 30 * values.timeInterval
        ),
        description: values.description,
        personId: currentClickedItem?._id,
        duration: values.timeInterval,
      };
    } else {
      data = {
        title: values.title,
        description: values.description,
        startTimestamp: convertDateStringToTimeStamp(values.scheduleTimeArray),
        endTimestamp: convertDateStringToTimeStamp(
          values.scheduleTimeArray + 60000 * 30 * values.timeInterval
        ),
        dashboardType:
          currentClickedItem.dashboardType === "CONTRACT_SCHEDULE"
            ? "CONTRACT_SCHEDULE"
            : "SCHEDULE",
        taggedPersonIds: [selectedClient?._id],
        duration: values.timeInterval,
      };
    }
    if(defaultValues._id){
      dispatch(
        landingPageAction.updateReminder(
          defaultValues._id,
          data
        )
      );
    }else{
      dispatch(
        landingPageAction.createReminder(data)
      );
    }
    setShowModal(!showModal);
    setCurrentClickeditem(" ")
  };

  const handleCancel = () => {
    setShowModal(false);
    setCurrentClickeditem(" ")
  };
  // <---------------functions for handling duration in schedule-------------->
  const decrementDuration = (e) => {
   if(editcase){
      if (defaultValues.timeInterval > 1) {
      setDefaultValues({
        ...defaultValues,
      timeInterval: defaultValues.timeInterval - 1,
      });
    }
  } else{
    if (defaultValues.timeInterval > 1) {
      setDefaultValues({
        // ...defaultValues,
        timeInterval: defaultValues.timeInterval - 1,
      });
    }
  }
  };

  const incrementDuration = () => {
  if(editcase)
  {
 
    if (defaultValues.timeInterval < 16) {
      setDefaultValues({
        ...defaultValues,
        timeInterval: defaultValues.timeInterval + 1,
      });
    }
    }else{
  if (defaultValues.timeInterval < 16) {
      setDefaultValues({
       timeInterval: defaultValues.timeInterval + 1,
      });
  };
}}
  const selectedDuration = () => {
    let duration;
    if (defaultValues.timeInterval) {
      duration = options[defaultValues.timeInterval - 1]?.label;
    }
    return duration;
  };

  const displayTitle = (item) => {
    let letters = isTab ? 50 : isMobile || spot1 ? 60 : 75
    if (item.type === "SCHEDULE") {
      return item.title?.slice(0, letters) + (item.title?.length > letters ? "..." : "");
    }
  };

  return (
    <>
      {/* <-------------Modal for  schedule section--------------> */}
      <ScheduleModal
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
        currentClickedItem={currentClickedItem}
        form={form}
        decrementDuration={decrementDuration}
        selectedDuration={selectedDuration}
        incrementDuration={incrementDuration}
        selectedClient={selectedClient}
        setShowClientList={setShowClientList}
        showClientList={showClientList}
        showDeallocateConfirm={showDeallocateConfirm}
      />

      <Modal
        visible={showClientList}
        onOk={() => {}}
        onCancel={() => setShowClientList(false)}
        footer={false}
      >
        <ClientList
          setNewRedirectClientToOffer={() => {}}
          setSelectedClient={() => {}}
          selectClientHandle={() => {}}
          selectedClient={selectedClient}
          setGetClientDetail={() => {}}
          listSource={"calendar"}
          selectClient={selectClient}
          page={page}
          setPage={setPage}
          showSearchInput={showSearchInput}
          setShowSearchInput={setShowSearchInput}
          value={value} setValue={setValue}
          isfilterArchive={isfilterArchive}
          setIsFilterArchive={setIsFilterArchive}
        />
      </Modal>
      {/* <--------------header for schedule section------------> */}
      <ScheduleHeader
        setShowModal={setShowModal}
        setSelectedClient={setSelectedClient}
        setDefaultValues={setDefaultValues}
        setEditCase={ seteditcase}
        section={section}
      />

      {getScheduleLoading && section !== "HOME" ? (
        <div style={{ marginTop: "20px" }}>
          <Loading />
        </div>
      ) : getScheduleLoading && section === "HOME" ? null : (
        <ScheduleList
          handleListItemClick={handleListItemClick}
          currentScheduleData={currentScheduleData}
          setSelectedClient={setSelectedClient}
          showDeleteConfirm={showDeleteConfirm}
          displayTitle={displayTitle}
          section={section}
        />
      )}
    </>
  );
};

export default Tasks;
