import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { landingPageAction } from "../state/actions";
import { convertDateStringToTimeStamp } from "../utils/utilFunctions";
import { ClientList } from "./../../Client/components/ClientList";
import ReminderModal from "./ReminderModal";
import ReminderHeader from "./ReminderHeader";
import ReminderList from "./ReminderList";
import { options } from "../../Common/commondata/Option";
import { Loading } from "../../Common/components/Loading";
import { clientAction } from "../../Client/state/actions";
import { useLocation } from "react-router-dom";
import axios from "axios";

const { confirm } = Modal;
const Reminders = ({ newDate, section }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { state, pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [typeOfCreation, setTypeOfCreation] = useState(false);
  const [editCase, setEditCase] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    reminderDate: moment(),
    scheduleTimeArray: moment(),
    timeInterval: 2,
  });
  const [showClientList, setShowClientList] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [reminderTypeNormal, setReminderTypeNormal] = useState(true);
  const [reminderType, setReminderType] = useState("REMINDER");
  const [currentClickedItem, setCurrentClickeditem] = useState("");
  const [currentReminderData, setCurrentReminderData] = useState([]);

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [value, setValue] = useState("");
  const [page, setPage]=useState([1]);
  const [isfilterArchive, setIsFilterArchive] = useState("");

  const { getRemindersData, getRemindersLoading, renderReminder } = useSelector(
    (state) => state.landingPageReducer.reminderReducer
  );
  const {  getScheduleLoading, } = useSelector(
    (state) => state.landingPageReducer.scheduleReducer
  );
  useEffect(() => {
    setCurrentReminderData(getRemindersData);
  }, [getRemindersData]);

  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();
  useEffect(() => {
      let pathnme = pathname?.split("/");
      let date = pathnme?.[2]?.split("-");
      if (Array.isArray(date)) {
        newDate = ( moment(new Date(date[0], date[1] - 1, date[2]).setHours(9)) );
      }
      dispatch(landingPageAction.getRemindersList({timestamp: moment(newDate).valueOf(), unmounted, sourceAxios}));

      return () => {
        unmounted.value = true;
        sourceAxios.cancel("Cancelling in cleanup");
      };
  }, [state, renderReminder]);

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const selectClient = (client) => {
    setSelectedClient(client);
    setShowClientList(false);
  };
  const source = "Reminder";

  const handleOk = (values) => {
   let data;
    if (currentClickedItem.type === "CLIENT_EVENT") {
      data = {
        name: values.title,
        description: values.description,
        dashboardType: typeOfCreation ? "SCHEDULE" : "REMINDER",
        type: currentClickedItem.type,
        endTimestamp: convertDateStringToTimeStamp(
          values.reminderDate + 60000 * 30 * 4
        ),
      };
    } else if (currentClickedItem.type === "BIRTHDAY") {
      data = {
        dashboardType: typeOfCreation ? "SCHEDULE" : "REMINDER",
        type: currentClickedItem.type,
        startTimestamp: convertDateStringToTimeStamp(values.reminderDate),
        endTimestamp: convertDateStringToTimeStamp(
          values.reminderDate + 60000 * 30 * 4
        ),
        description: values.description,
        personId: currentClickedItem?._id,
      };
    } else {
      data = {
        title: values.title,
        description: values.description,
        startTimestamp: convertDateStringToTimeStamp(values.reminderDate),
        dashboardType:
          currentClickedItem.dashboardType === "CONTRACT_REMINDER" &&
          !typeOfCreation
            ? "CONTRACT_REMINDER"
            : currentClickedItem.dashboardType === "CONTRACT_REMINDER" &&
              typeOfCreation
            ? "CONTRACT_SCHEDULE"
            : typeOfCreation
            ? "SCHEDULE"
            : "REMINDER",
        taggedPersonIds: [selectedClient?._id],
        // type: reminderType,
      endTimestamp: convertDateStringToTimeStamp(
          values.reminderDate + 60000 * 30 * 4
        ),
      };
    }
    if (typeOfCreation) {
      data.startTimestamp = convertDateStringToTimeStamp(
        values.scheduleTimeArray
      );
      data.endTimestamp = convertDateStringToTimeStamp(
        values.scheduleTimeArray + 60000 * 30 * values.timeInterval
      );
      data.duration = values.timeInterval;
    }
    if (editCase) {
      dispatch(
        landingPageAction.updateReminder(
          defaultValues._id,
          data,
          convertDateStringToTimeStamp(newDate),
          source
        )
      );
      
    }
    if (!editCase) {
      dispatch(
        landingPageAction.createReminder(
          data,
          convertDateStringToTimeStamp(newDate)
        )
      );
    }
    setShowModal(!showModal);
    setCurrentClickeditem("")
  };

  const handleCancel = () => {
    setCurrentClickeditem("");
    setDefaultValues({
      reminderDate: moment(),
      scheduleTimeArray: moment(),
      timeInterval: 2,
    });
    setShowModal(false);
  };

  let handleDelete = (item) => {
    dispatch(
      landingPageAction.deleteReminderById(
        item._id,
        item.type,
        convertDateStringToTimeStamp(newDate),
        typeOfCreation ? "SCHEDULE" : "REMINDER"
      )
    );
  };

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Are you sure you want to delete this Reminder?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const showDeallocateConfirm = (id) => {
    confirm({
      title: "Are you sure you want to unlink this user?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setSelectedClient(null);
      },
      onCancel() {},
    });
  };
  const handleLinkClientToAvatar = () => {
    if (
      currentClickedItem.type === "BIRTHDAY" ||
      currentClickedItem.type === "CLIENT_EVENT"
    ) {
      return;
    }
    setPage([1]);
    dispatch(clientAction.clearGetClientData());
    setShowClientList(!showClientList);
  };
  // function for  when we click on the list to update the reminder or schedule
  const openUpdateReminder = (id, data) => {
    let newData = {};
    if (data.type === "CLIENT_EVENT") {
      newData = {
        ...data,
        title: data.title,
        description: data.description,
        reminderDate: moment(data.startTimestamp),
        timeInterval: 2,
      };
    } else if (data.type === "BIRTHDAY") {
      newData = {
        ...data,
        title: data.fullName ? data?.fullName : "",
        description: "BIRTHDAY",
        reminderDate: moment(data.startTimestamp),
        timeInterval: 2,
      };
    } else if (data.type === "CONTRACT_REMINDER") {
      newData = {
        ...data,
        scheduleTimeArray: moment(),
        reminderDate: moment(),
        timeInterval: 2,
      };
    } else {
      newData = {
        ...data,
        reminderDate: moment(data.timestampToCompare),
        timeInterval: 2,
      };
    }
    setReminderTypeNormal(true);
    setDefaultValues(newData);
    setEditCase(true);
    setShowModal(true);
    setCurrentClickeditem(data);
  };

 
  const selectedDuration = () => {
    let duration = options[defaultValues.timeInterval - 1]?.label;
    return duration;
  };

  // when click on any of the list item
  const handleListItemClick = (item) => {
    if (item.type === "REMINDER") {
      openUpdateReminder(item._id, item);
      setSelectedClient(item?.taggedPersonIds?.[0]);
      setTypeOfCreation(false);
      setReminderType(item.type);
    } else if (item.type === "CONTRACT_REMINDER") {
      openUpdateReminder(item._id, item);
      setSelectedClient(item?.taggedPersonIds?.[0]);
      setTypeOfCreation(false);
      setReminderTypeNormal(false);
      setReminderType(item.type);
    } else if (item.type === "CLIENT_EVENT") {
      openUpdateReminder(item._id, item);
      setSelectedClient(item?.taggedPersonIds?.[0]);
      setTypeOfCreation(false);
      setReminderType(item.type);
    } else if (item.type === "BIRTHDAY") {
      openUpdateReminder(item._id, item);
      setSelectedClient(item);
      setTypeOfCreation(false);
      setReminderType(item.type);
    } else {
      return;
    }
  };

  const displayTitle = (item, toolTip) => {
    if (item.type === "REMINDER") {
      return toolTip
        ? item.title
        : item.title?.slice(0, 30) + (item.title?.length > 30 ? "..." : "");
    }
    if (item.type === "CONTRACT_REMINDER") {
      const maxLength = section === "HOME" ? 35 : 30;
      return toolTip
        ? item.title
        : item.title?.slice(0, maxLength) + (item.title?.length > maxLength ? "..." : "");
    }
    if (item.type === "EVENT") {
      return toolTip
        ? item.address
        : item.address?.slice(0, 30) + (item.address?.length > 30 ? "..." : "");
    }
    if (item.type === "BIRTHDAY") {
      return item?.fullName;
    }
    if (item.type === "CLIENT_EVENT") {
      return toolTip
        ? item.title
        : item.title?.slice(0, 30) + (item.title?.length > 30 ? "..." : "");
    }
  };

  return (
    <>
      {/* <----------------- Modal box for creating any reminders-------------------> */}

      <ReminderModal
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        defaultValues={defaultValues}
        typeOfCreation={typeOfCreation}
        setTypeOfCreation={setTypeOfCreation}
        reminderTypeNormal={reminderTypeNormal}
        currentClickedItem={currentClickedItem}
        form={form}
       
        setDefaultValues={ setDefaultValues}
        // decrementDuration={decrementDuration}
        selectedDuration={selectedDuration}
        // incrementDuration={incrementDuration}
        editCase={editCase}
        selectedClient={selectedClient}
        showClientList={showClientList}
        setShowClientList={setShowClientList}
        showDeallocateConfirm={showDeallocateConfirm}
        reminderType={reminderType}
        handleLinkClientToAvatar={handleLinkClientToAvatar}
      />
      {/* <----------------Modal for opening  client detail-------------------> */}

      <Modal
        visible={showClientList}
        onOk={() => {}}
        onCancel={() => setShowClientList(false)}
        footer={false}
      >
        <ClientList
          setNewRedirectClientToOffer={() => {}}
          selectedClient={selectedClient}
          setGetClientDetail={() => {}}
          listSource={"calendar"}
          selectClient={selectClient}
          page={page}
          setPage={setPage}
          showSearchInput={showSearchInput}
          setShowSearchInput={setShowSearchInput}
          value={value} setValue={setValue}
          isfilterArchive={isfilterArchive}
          setIsFilterArchive={setIsFilterArchive}
        />
      </Modal>

      {/* <-------------Reminder headers-------------------> */}

      <ReminderHeader
        setShowModal={setShowModal}
        setEditCase={setEditCase}
        setTypeOfCreation={setTypeOfCreation}
        setSelectedClient={setSelectedClient}
        setReminderTypeNormal={setReminderTypeNormal}
        setReminderType={setReminderType}
        setDefaultValues={setDefaultValues}
      />

      {/* ------------------------Reminder list------------------------ */}
      <>
        {getRemindersLoading || (section === "HOME" && getScheduleLoading) ? (
          <div style={{ marginTop: "25px" }}>
            {" "}
            <Loading />{" "}
          </div>
        ) : (
          <ReminderList
            showDeleteConfirm={showDeleteConfirm}
            currentReminderData={currentReminderData}
            handleListItemClick={handleListItemClick}
            displayTitle={displayTitle}
            section={section}
          />
        )}
      </>
    </>
  );
};

export { Reminders };
