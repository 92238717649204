import { Row, Col, Modal } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import { Loading } from '../../Common';

const PropertyInfo = ({isModalOpen, setIsModalOpen}) => {

    const {ownersInfoLoading, ownersInfo} = useSelector((state) => state.PropertyReducer)

    const rowStyle = {
        marginBottom: '12px',
        borderBottom: '1px solid #f0f0f0',
        paddingBottom: '8px',
      };
      
      const labelStyle = {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#555',
      };
      
      const valueStyle = {
        fontSize: '14px',
        color: '#000',
      };

      const handleCancel = () => {
        setIsModalOpen({...isModalOpen,propertyInfoModalOpen:false})
    }

  return (
    <Modal open={isModalOpen?.propertyInfoModalOpen} footer={false} onCancel={handleCancel} title={<span style={{display:"flex",justifyContent:"center",fontWeight:"bold",color:"#1990ff",fontSize:"20px"}}>PROPERTY INFO</span>} width={650}>
        {ownersInfoLoading ? <Loading/> : 
        <div style={{ padding: '0 10px' }}>
        <Row style={rowStyle}>
          <Col span={24} style={{display:"flex",justifyContent:"center",fontWeight:"bold",fontSize:"17px",width:"100%"}}>
            {ownersInfo?.PropertyAddressFull || 'N/A'}
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            Parcel #:
          </Col>
          <Col span={18} style={valueStyle}>
            {ownersInfo?.ParcelNumberRaw || 'N/A'}
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            Tax Legal Desc:
          </Col>
          <Col span={18} style={valueStyle}>
            {ownersInfo?.TaxLegalDescription || 'N/A'}
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            Owners:
          </Col>
          <Col span={18} style={valueStyle}>
            {ownersInfo?.Owners?.length > 0 ? 
            <ol style={{ margin: 0, paddingLeft: '16px' }}>
                {ownersInfo?.Owners?.map((owner, index) => (
                <li key={index} style={{ marginBottom: '4px' }}>{owner}</li>
                ))}
            </ol>
            : 'N/A'}
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            County:
          </Col>
          <Col span={18} style={valueStyle}>
            {ownersInfo?.CountyOrParish || 'N/A'}
          </Col>
        </Row>
      </div>}
    </Modal>
  )
}

export default PropertyInfo